import React from "react";
import '../stylesheets/Menu.css';

function Navbar () {
  return (
    <div>
      <ul>
        <li><a href='#contenedor1'> </a></li>
        <li><a href='#contenedor2'>Conocer</a></li>
        <li><a href='#contenedor3'>Imagenes</a></li>
        <li><a href='#contenedor4'>Servicios</a></li>
        <li><a href='#contenedor5'>Contacto</a></li>
      </ul>
    </div>
  );
}

export default Navbar;